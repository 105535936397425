import { REM_VALUES } from 'Constants/constants';

interface StylingObjectDesktop {
	[resolution: string]: number;
}

export const getRemValueForAllScreenSizes = (
	pixelValue: number,
	isDesktop?: boolean,
) => {
	let val: StylingObjectDesktop | number = {};
	if (isDesktop) {
		Object.keys(REM_VALUES.DESKTOP).map(minResolution => {
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			val[minResolution] = pixelValue / REM_VALUES.DESKTOP[minResolution];
		});
	} else val = pixelValue / REM_VALUES.MOBILE;
	return val;
};

export const getRemValuesForStyling = (
	stylesInPixels: number | { [style: string]: number },
	isDesktop?: boolean,
) => {
	let style: string = '';
	Object.keys(stylesInPixels).map(styleName => {
		const styleValueInRem: StylingObjectDesktop | number =
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			getRemValueForAllScreenSizes(stylesInPixels[styleName], isDesktop);
		if (typeof styleValueInRem === 'number')
			style += `${styleName}:${styleValueInRem}rem;`;
		else {
			Object.keys(styleValueInRem).map((minResolution, index) => {
				if (index === 0) {
					style += `${styleName}:${styleValueInRem[minResolution]}rem;`;
				}
				style += `@media (min-width: ${minResolution}px){
					${styleName}:${styleValueInRem[minResolution]}rem;;
				}`;
			});
		}
	});
	return style;
};
