import { MutableRefObject, useEffect } from 'react';

type TOptions = {
	onTouchEvent?: boolean;
};

const useCaptureClickOutside = (
	elementRef: MutableRefObject<any>,
	outsideClickHandler: any,
	exceptionRefs: MutableRefObject<any>[] = [],
	options?: TOptions,
) => {
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		if (options?.onTouchEvent)
			document.addEventListener('touchstart', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			if (options?.onTouchEvent)
				document.removeEventListener('touchstart', handleClickOutside);
		};
	});

	const handleClickOutside = (event: any) => {
		let isException = false;
		exceptionRefs.forEach(ref => {
			isException =
				isException ||
				((ref as any).current &&
					(ref as any).current.contains(event.target));
		});
		if (
			elementRef.current &&
			!elementRef.current.contains(event.target) &&
			!isException
		) {
			outsideClickHandler(event);
		}
	};
};

export default useCaptureClickOutside;
