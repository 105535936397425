/*eslint-disable */
import React from 'react';
import cookies from 'nookies';

// TODO: use generic and define the return type
const withCookies = (WrappedComp: any) =>
	class extends React.Component {
		render() {
			return <WrappedComp {...this.props} cookies={cookies.get()} />;
		}
	};

export default withCookies;
